/*! 🧮🧩 2020*/
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const debug = false;

export default new Vuex.Store({
  //modules: {
  //    spark,
  //    security,
  //},
  state: {
    count: 0,
    artistId: 611,
    carousels: [],
    artists_data: [],
  },
  mutations: {
    update(state, update) {
      state[update.payload] = update.value;
      return true;
    },
  },
  actions: {
    async load({ state, commit }, payload) {
      if (state[payload].length > 0) {
        return;
      }
      try {
        const response = await fetch(
          `https://cdn-p.smehost.net/sites/5cfaf3980b294dd89a79248f35560b2f/mothership/artist/${state.artistId}/${payload}.json`,
          { method: 'GET' }
        );
        const data = await response.json();
        if (Array.isArray(data) === false) {
          commit('update', {
            payload,
            value: [data],
          });
        } else {
          data.sort((a, b) => {
            return parseInt(a.order, 10) - parseInt(b.order, 10);
          });
          commit('update', {
            payload,
            value: data,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  strict: debug,
  //plugins: debug ? [createLogger()] : [],
});
