/*! 🧮🧩 2020*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import { gsap } from 'gsap';

import Landing from '../vue/Landing.vue';

import routes from './routes';
import store from './store';
import moduleLoader from './util/moduleLoader';
import './autoload/smoothScroll';
//import burger from './modules/burger';
//import './util/scroll';

window.releasesLayout1Loaded = () => {
    const _releases = document.querySelectorAll('.release');
    console.log(_releases);
    const _releaseTimeline = gsap.timeline();
    for (const _release of _releases) {
        _releaseTimeline.fromTo(_release,
            {
                yPercent: 10,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
                duration: 1,
                stagger: 0.3,
                ease: 'power1.inOut',
            },
            '>-.7');
    }
};


moduleLoader.scan(document.body);

const element = document.querySelector('#app');
if (element){
    Vue.use(Vuex);
    Vue.use(VueRouter);
    //Vue.use(CloudflareVideoPlayer);
    Vue.component('CloudflareVideoPlayer', () => import(/* webpackChunkName: "video" */ '../vue/components/CloudflareVideoPlayer.vue'));

    Vue.component('hero', () => import(/* webpackChunkName: "hero" */ '../vue/components/hero.vue'));
    //Vue.component('screen', () => import(/* webpackChunkName: "screen" */ '../vue/components/screen.vue'));
    //Vue.component('mobile-screen', () => import(/* webpackChunkName: "screen" */ '../vue/components/mobile-screen.vue'));
    //Vue.component('desktop-screen', () => import(/* webpackChunkName: "screen" */ '../vue/components/desktop-screen.vue'));

    const documentLinks = document.querySelectorAll('a.js-router');

    const router = new VueRouter({
        mode: 'history',
        routes,
    });
    /*
router.afterEach(() => {
    window.scroll({
        top: 0,
        behavior: 'smooth',
    });
    burger.close();
});
*/

    for (const documentLink of documentLinks) {
        documentLink.addEventListener('click', event => {
            event.preventDefault();
            const link = event.currentTarget.getAttribute('href');
            if (router.currentRoute.path !== link) {
                router.push(link);
            }
        });
    }

    new Vue({
        el: element,
        render: h => h(Landing),
        router,
        store,
    });
}

/*
window.addEventListener('beforeunload', function () {
    window.scrollTo(0, 0);
});
*/