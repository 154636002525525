/*! 🧮🧩 2020*/
import LoadingComponent from '../vue/Loading.vue';
import ErrorComponent from '../vue/LoadingError.vue';
//import NotFound from '../vue/NotFound.vue';

const delay = 100;
const timeout = 10000;

const lazyLoadView = ({ component, loading, error, delay, timeout }) => {
    const AsyncHandler = () => ({
        component,
        loading,
        error,
        delay,
        timeout,
    });
  
    return () => Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        },
    });
};

const home = lazyLoadView({
    component: import(/* webpackChunkName: "home" */ '../vue/Home.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});


export default [
    {
        path: '/',
        component: home,
        
    },
    {
        path: '/about/',
        component: home,
        
    },
    {
        path: '/listen/',
        component: home,
        
    },
    {
        path: '/watch/',
        component: home,
        
    },
    {
        path: '*',
        component: home,
        
    },
];